.conversight .spinner-container {
  &.auto {
    height: auto;
  }

  &.small {
    width: 24px;
    height: 24px;
    .spinner {
      height: 24px;
      width: 24px;
    }
  }
}
